(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name phases.inscription.controller:InscriptionPhaseCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.phases.inscription')
    .controller('InscriptionPhaseCtrl', InscriptionCtrl);

  function InscriptionCtrl(phases, genders, categories, sports, PhasesInscriptions, townHalls, $mdToast, $filter) {
    var vm = this;
    vm.ctrlName = 'InscriptionPhaseCtrl';
    vm.phases = phases;
    vm.inscriptions = [];
    vm.myTownHalls = townHalls;
    vm.townHallSearch = "";
    vm.getInscriptions = getInscriptions;
    vm.categories = categories;
    vm.selectedTownHall = undefined;
    vm.disableButtons = true;

    if (vm.myTownHalls.length === 1) {
      vm.onlyOneTownHall = true;
      vm.selectedTownHall = vm.myTownHalls[0];
      vm.isSelectedTownhallUndefined = false;

      vm.getInscriptions();
    }
    else{
      vm.isSelectedTownhallUndefined = true;
      vm.onlyOneTownHall = false;
    }

    var findCategory = function (categoryId) {
      return _.find(vm.categories, function (c) {
        return c.id === categoryId;
      });
    };

    var findSport = function (sportId) {
      return _.find(sports, function (s) {
        return s.id === sportId;
      });
    };

    var findGender = function (genderId) {
      return _.find(genders, function (g) {
        return g.id === genderId;
      })
    };

    vm.getPhaseIndexOf = function (phase) {
      return _.findIndex(vm.phases, function (p) {
        return p.id === phase.id;
      });
    };

    vm.hasChangedModel = function () {
      vm.disableButtons = false;
    };


    function getInscriptions() {
      vm.isSelectedTownhallUndefined = false;
      vm.inscriptions = [];
      PhasesInscriptions.query({townHallId: vm.selectedTownHall.id}, function (inscriptions) {
        angular.forEach(inscriptions, function (inscription) {
          var myInscription = {};
          myInscription.team = inscription.team;
          myInscription.teamName = inscription.team.name;
          myInscription.teamNameOrder = $filter('lowercase')(inscription.team.name);
          var category = findCategory(inscription.team.categoryId);
          myInscription.teamCategory = 'CATEGORIES.' + category.name;
          myInscription.teamCategoryOrder = category.startYear;
          myInscription.teamSport = $filter('translate')('SPORTS.' + findSport(inscription.team.sportId).name);
          myInscription.teamGender = findGender(inscription.team.genderId).name;
          myInscription.phases = inscription.phases;
          vm.inscriptions.push(myInscription);
        });
        vm.inscriptions = _.sortByOrder(vm.inscriptions, ['teamSport', 'teamCategoryOrder', 'teamGender'], [true, false, true]);
        vm.originalInscriptions = _.cloneDeep(vm.inscriptions);
      });
    }

    vm.discardChanges = function () {
      vm.inscriptions = _.cloneDeep(vm.originalInscriptions);
      vm.disableButtons = true;
    };

    vm.submitInscription = function () {
      var inscriptionsToSave = [];
      angular.forEach(vm.inscriptions, function (inscriptionModel) {
        var inscriptionToSave = {
          team: inscriptionModel.team,
          phases: inscriptionModel.phases
        };
        inscriptionsToSave.push(inscriptionToSave);
      });

      PhasesInscriptions.save(inscriptionsToSave,
        function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('PHASES.COMPLETED_INSCRIPTIONS'))
              .position('bottom left')
              .hideDelay(3000)
          );
        }, function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('TOAST_ERROR'))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
    };
  }
}());
